<template>
	<div v-if="users?.length">
		<h1>承認待ちユーザー一覧</h1>
  	<div class="error" v-if="dbError">DBエラー: {{ dbError }}</div>
  	<div class="error" v-if="dbWriteError">DB書き込みエラー: {{ dbWriteError }}</div>
    <ConfirmDialog></ConfirmDialog>

		<table class="design03">
 			<tr>
				<th class="table-icon"></th>
  			<th>ユーザー名</th>
  			<th>メールアドレス</th>
  			<th>アカウント種別選択</th>
				<th></th>
  		</tr>
			<tr v-for="user in users" :key="user.id">
				<td>
					<Avatar
						aria-haspopup="true" aria-controls="overlay_menu"
						:image="user.photoURL" shape="circle"
					/>
				</td>
  			<td>{{user.displayName}}</td>
  			<td>{{user.email}}</td>
				<td>
					<div class="cp_ipselect cp_sl01">
						<select required v-model="user.role">
  						<option v-for="role in roles" :key="role.code" v-bind:value="role.code">
    						{{ role.name }}
  						</option>
						</select>
					</div>
				</td>
				<td>
      		<Button @click="confirm1(user)" label="承認" :loading="user.loading && user.role === 'pending'"></Button>
				</td>
			</tr>
		</table>

	</div>

</template>

<script>
import {computed, ref} from 'vue'
import getCollectionWithLimit from '../composables/getCollectionWithLimit'
import useCollection from '../composables/useCollection.js'
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';

export default {
	components: {ConfirmDialog},
	setup() {
		const {error: dbError, documents}
			= getCollectionWithLimit(`/membersOnly/v1/users`, 100, ["role", "==", "pending"])

		const {error: dbWriteError, addDoc}
			= useCollection("/adminOnly/v1/customClaimChanges")

		const loadingUser = ref([])
		const users = computed(() => {
			if(documents.value) {
				return documents.value.map(doc => {
					const loading = loadingUser.value.includes(doc.id) ? true : false
					return {...doc, loading}
				})
			}
		})

    const roles = ref([
        {name: 'クライアント', code: 'client'},
        {name: 'スタッフ', code: 'staff'},
        {name: '管理者', code: 'manager'}
    ]);

    const confirm = useConfirm();
  	const confirm1 = (user) => {
			if(user.role && user.role != 'pending') {
      	confirm.require({
          message: `${user.email}→${user.role}`,
          header: '承認を実行しますか？',
					acceptLabel: "実行",
					rejectLabel: "中止",
          icon: 'pi pi-info-circle',
          accept: () => {
						console.log('accepted')
						console.log(user.email, user.role)

						loadingUser.value.push(user.id)

						addDoc({
							uid: user.id,
							key: "role",
							value: user.role,
						})
          },
          reject: () => {
						console.log('rejected')
            // toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
          }
      	});
			}
  	}

		return {dbError, dbWriteError, users, roles, confirm1 }
	}
}
</script>

<style scoped>
	.design03 {
 		width: 100%;
 		text-align: center;
 		border-collapse: collapse;
 		border-spacing: 0;
 		border-top: solid 1px #778ca3;
	}

	.design03 tr:nth-child(2n+1) {
 		background: #e3e7f0;
	}

	.design03 th,
	.design03 td {
 		padding: 10px;
 		border-bottom: solid 1px #778ca3;
	}

	.design03 .table-icon {
		width: 50px;
	}

	.cp_ipselect {
		overflow: hidden;
		width: 90%;
		margin: 0 auto;
		text-align: center;
	}
	.cp_ipselect select {
		width: 100%;
		padding-right: 1em;
		cursor: pointer;
		text-indent: 0.01px;
		text-overflow: ellipsis;
		border: none;
		outline: none;
		background: transparent;
		background-image: none;
		box-shadow: none;
		-webkit-appearance: none;
		appearance: none;
	}
	.cp_ipselect select::-ms-expand {
    	display: none;
	}
	.cp_ipselect.cp_sl01 {
		position: relative;
		border: 1px solid #bbbbbb;
		border-radius: 2px;
		background: #ffffff;
	}
	.cp_ipselect.cp_sl01::before {
		position: absolute;
		top: 0.8em;
		right: 0.9em;
		width: 0;
		height: 0;
		padding: 0;
		content: '';
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid #666666;
		pointer-events: none;
	}
	.cp_ipselect.cp_sl01 select {
		padding: 8px 38px 8px 8px;
		color: #666666;
	}

</style>