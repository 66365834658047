<template>
	<div class="login-container">
		<div class="login-box">
  		<h3 style="color: #fff;">Login to your account</h3>

			<div class="error">
				{{error}}
			</div>

			<Button type="button" class="p-button-raised p-button-primary"
				:loading="isPending"
				@click="handleLogin">
    			<img alt="Google Sign In" src="../assets/google-logo.svg" style="width: 1.5rem" />
    			<span class="p-ml-2 p-text-bold">Googleでログイン</span>
			</Button>
		</div>
	</div>

</template>

<script>
import useLogin from '../composables/useLogin'
import {useRouter} from 'vue-router'

export default {
	setup() {
		const {error, isPending, login} = useLogin()
		const router = useRouter()

		const handleLogin = async () => {
			await login()
			if(!error.value) router.push({name: 'Dashboard'})
		}

		return {error, isPending, handleLogin}
	}

}
</script>

<style scoped>
	.p-button {
		color: #fff;
	}

	.login-container {
		padding-top: 200px;
		width: auto;
		height: 100vh;
  	background: url(../assets/bg-login.jpg);
  	background-position: center center;
  	background-repeat: no-repeat;
  	background-size: cover;
  	overflow: hidden;
  	background-color: #666;

	}

	.login-box {
		text-align:center;
		background:rgba(0,0,0,0.5);
    width: 360px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 100px 30px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;

	}
	@media screen and (max-width:480px) {
		.login-box {
   		padding: 30px;
   		width: 300px;
		}
	}


</style>