<template>
	<div class="content">
		<h1>Dashboard</h1>

  	<div>
    	Sample charts
			<div class="pie-charts">
				<div class="single-chart">
    			<canvas ref="canvasRef2" />
				</div>
				<div class="single-chart">
    			<canvas ref="canvasRef3" />
				</div>
			</div>

    	<canvas ref="canvasRef" />
  	</div>

	</div>
</template>

<script>
import { onMounted, ref } from 'vue';
import Chart from 'chart.js/auto'

export default {
	setup() {
		const canvasRef = ref(null)
		const canvasRef2 = ref(null)
		const canvasRef3 = ref(null)

    function getRand () {
      const arr = []
      for (let i = 0; i < 6; i++) {
        arr.push(Math.random())
      }
      return arr
    }

    function createCharts () {
      if (canvasRef.value === null) return
      const canvas = canvasRef.value.getContext('2d')
      if (canvas === null) return
      console.log(canvasRef.value?.getContext('2d'))
      const c = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
            label: 'TEST',
            data: getRand(),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
          }]
        }
      })
      // console.log(c)
    }

    function createCharts2 () {
      if (canvasRef2.value === null) return
      const canvas = canvasRef2.value.getContext('2d')
      if (canvas === null) return
      console.log(canvasRef2.value?.getContext('2d'))
      const c = new Chart(canvas, {
        type: 'doughnut',
        data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
            label: 'TEST',
            data: getRand(),
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(153, 102, 255, 0.5)',
              'rgba(255, 159, 64, 0.5)'
            ],
          }]
        }
      })
      // console.log(c)
    }

    function createCharts3 () {
			console.log('3')
      if (canvasRef3.value === null) return
      const canvas = canvasRef3.value.getContext('2d')
      if (canvas === null) return
      console.log(canvasRef3.value?.getContext('2d'))
      const c = new Chart(canvas, {
        type: 'line',
        data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
            label: 'TEST',
            data: getRand(),
            borderColor: "RGBA(33,33,33, 0.5)",
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(153, 102, 255, 0.5)',
              'rgba(255, 159, 64, 0.5)'
            ],
          }]
        }
      })
      // console.log(c)
    }
		onMounted(() => {
			createCharts()
			createCharts2()
			createCharts3()
			console.log('mounted')
		})

		return { canvasRef, canvasRef2, canvasRef3 }


	}

}
</script>

<style scoped>
	.pie-charts {
    display: flex;
		width: 100%;
	}
	.single-chart {
		width: 50%;
	}

</style>