import {ref} from 'vue'
import {projectAuth} from '../firebase/config'

const user = ref(projectAuth.currentUser)
const role = ref(null)

projectAuth.onAuthStateChanged(u => {
	user.value = u

	if (u) {
		u.getIdTokenResult(true).then(token => {
			if(token.claims.role) {
				role.value = token.claims.role
			}
		}).catch(err => {
			role.value = null
			console.log(err.message)
		})
	}
	// console.log('auth state changed. current user is:', u)
})

const getUser = () => {
	return { user, role }
}

export default getUser
