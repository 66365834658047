import { ref, watchEffect } from 'vue'
import {projectFireStore} from '../firebase/config'

const getCollection = (collection, query) => {
	const error = ref(null)
	const documents = ref(null)

	let collectionRef = projectFireStore.collection(collection)
		.orderBy('timeStamp')

	if(query) {
		collectionRef = collectionRef.where(...query)
	}

	const unsub = collectionRef.onSnapshot((snap) => {
		const results = []
		console.log('snapshot')

		snap.docs.forEach(doc => {
			doc.data().timeStamp && results.push({
				...doc.data(),
				id: doc.id,
			})
		})

		documents.value = results
		error.value = null
	}, (err) => {
		console.log(err.message)
		error.value = err.message
	})

	watchEffect((onInvalidate) => {
		onInvalidate(() => {
			unsub()
		})
	})

	return {error, documents}
}

export default getCollection
