<template>
  <div>
    <Menubar :model="items">
      <template #start>
				<Avatar label="I" style="background-color:#2196F3; color: #ffffff"/>
      </template>

      <template #end>
				<div v-if="user">
					<ProfileArea :user="user" />
				</div>
      </template>
    </Menubar>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import ProfileArea from '../components/ProfileArea.vue'
import useLogin from '../composables/useLogin'
import getUser from '../composables/getUser'

export default {
	components: { ProfileArea, },
  setup() {
    const {error: loginError, login} = useLogin()
		const {user, role} = getUser()

    const handleLogin = async () => {
      await login()
    }

		const items = computed(() => {
			if (role.value) {
				return menuModel.value
			} else {
				return []
			}
		})

    const menuModel = ref([
      {
        label:'ダッシュボード',
        icon:'pi pi-fw pi-chart-line',
				to: {name: 'Dashboard'},
				visible: () => user.value
      },
      {
        label:'売上情報',
        icon:'pi pi-fw pi-money-bill',
				to: {name: 'CampaignSummary', params:{ campaignId: 'main'}},
				visible: () => user.value && role.value
      },
      {
        label:'ユーザー管理',
        icon:'pi pi-fw pi-users',
				to: {name: 'Users'},
				visible: () => user.value && role.value === "manager"
      },
      {
        label:'File',
        icon:'pi pi-fw pi-file',
				visible: false,
        items:[
             {
                 label:'New',
                 icon:'pi pi-fw pi-plus',
                 items:[
                     {
                         label:'Bookmark',
                         icon:'pi pi-fw pi-bookmark'
                     },
                     {
                         label:'Video',
                         icon:'pi pi-fw pi-video'
                     },
                 ]
             },
             {
                 label:'Delete',
                 icon:'pi pi-fw pi-trash'
             },
             {
                 separator:true
             },
             {
                 label:'Export',
                 icon:'pi pi-fw pi-external-link'
             }
        ]
      },
      {
        label:'Users',
        icon:'pi pi-fw pi-user',
				visible: () => false,
        items:[
              {
                  label:'New',
                  icon:'pi pi-fw pi-user-plus',
              },
              {
                  label:'Delete',
                  icon:'pi pi-fw pi-user-minus',
              },
              {
                  label:'Search',
                  icon:'pi pi-fw pi-users',
                  items:[
                      {
                          label:'Filter',
                          icon:'pi pi-fw pi-filter',
                          items:[
                              {
                                  label:'Print',
                                  icon:'pi pi-fw pi-print'
                              }
                          ]
                      },
                      {
                          icon:'pi pi-fw pi-bars',
                          label:'List'
                      }
                  ]
              }
          ]
      },
      {
          label:'Events',
          icon:'pi pi-fw pi-calendar',
					visible: () => false,
          items:[
              {
                  label:'Edit',
                  icon:'pi pi-fw pi-pencil',
                  items:[
                      {
                          label:'Save',
                          icon:'pi pi-fw pi-calendar-plus'
                      },
                      {
                          label:'Delete',
                          icon:'pi pi-fw pi-calendar-minus'
                      },
                  ]
              },
              {
                  label:'Archieve',
                  icon:'pi pi-fw pi-calendar-times',
                  items:[
                      {
                          label:'Remove',
                          icon:'pi pi-fw pi-calendar-minus'
                      }
                  ]
              }
         ]
      },

  	]);

    return { items, handleLogin, user, role }
  }
}
</script>

<style scoped>
	.p-menubar {
		margin: 0;
		padding: 0.5rem 1rem;
		border: none;
		border-radius: 0;
		position: fixed;
		width: 100%;
	}

</style>