import { ref } from 'vue'
import {projectFireStore} from '../firebase/config'

const useCollection = (collection) => {
	const error = ref(null)

	const addDoc = async (msg) => {
		error.value = null

		try {
			await projectFireStore.collection(collection).add(msg)

		} catch (err) {
			console.log(err.message)
			error.value = err.message
		}
	}

	return {error, addDoc}
}


export default useCollection