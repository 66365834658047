<template>
	<div class="content">
		<h1>ユーザー一覧</h1>
		<RegisteredUsers />

		<PendingUsers />
	</div>
</template>

<script>
import RegisteredUsers from '../components/RegisteredUsers.vue'
import PendingUsers from '../components/PendingUsers.vue'

export default {
	components: {RegisteredUsers, PendingUsers},

}
</script>

<style>

</style>