<template>
  <div class="error" v-if="dbError">DBエラー: {{ dbError }}</div>
	<div v-if="users">
		<table class="design03">
 			<tr>
				<th class="table-icon"></th>
  			<th>ユーザー名</th>
  			<th>メールアドレス</th>
  			<th>アカウント種別</th>
  		</tr>
			<tr v-for="user in users" :key="user.id">
				<td>
					<Avatar
						aria-haspopup="true" aria-controls="overlay_menu"
						:image="user.photoURL" shape="circle"
					/>
				</td>
  			<td>{{user.displayName}}</td>
  			<td>{{user.email}}</td>
  			<td>{{roleText(user.role)}}</td>
			</tr>
		</table>　
	</div>

</template>

<script>
import {computed} from 'vue'
import getCollectionWithLimit from '../composables/getCollectionWithLimit'
import roleText from '../composables/roleText'

export default {
	setup() {
		const {error: dbError, documents}
			= getCollectionWithLimit(`/membersOnly/v1/users`, 100, ["role", "!=", "pending"])

		const users = computed(() => {
			if(documents.value) {
				const arr = documents.value.filter(x => x.email != "sc.02e.sf@gmail.com")
				return arr.map(doc => {
					return {...doc}
				})
			}
		})

		return {dbError, users, roleText}
	}
}
</script>

<style scoped>
	.design03 {
 		width: 100%;
 		text-align: center;
 		border-collapse: collapse;
 		border-spacing: 0;
 		border-top: solid 1px #778ca3;
	}

	.design03 tr:nth-child(2n+1) {
 		background: #e3e7f0;
	}

	.design03 th,
	.design03 td {
 		padding: 10px;
 		border-bottom: solid 1px #778ca3;
	}

	.design03 .table-icon {
		width: 50px;
	}
</style>