<template>
  <Menubar />
  <Sidebar v-model:visible="visibleLeft">
	  Content
  </Sidebar>

  <router-view/>
</template>

<script>
import { ref } from '@vue/reactivity'
import Menubar from './components/Menubar.vue'

export default {
  components: { Menubar, },
  setup() {
    const visibleLeft = ref(false)

    return {visibleLeft}
  },
}
</script>
<style>

</style>
