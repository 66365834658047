import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyApb73MZzycmR-OQhBbBIrH_PWrWa8OrpE",
  authDomain: "influ-dashboard-prod.firebaseapp.com",
  projectId: "influ-dashboard-prod",
  storageBucket: "influ-dashboard-prod.appspot.com",
  messagingSenderId: "108621521393",
  appId: "1:108621521393:web:6d509843a85bad5e036450",
  measurementId: "G-VRKYCD7NG3"
};

firebase.initializeApp(firebaseConfig)
const projectFireStore = firebase.firestore()
const projectStorage = firebase.storage()
const projectAuth = firebase.auth()
const googleProvider = new firebase.auth.GoogleAuthProvider()

const timeStamp = firebase.firestore.FieldValue.serverTimestamp

export {projectFireStore, projectAuth, projectStorage, timeStamp, googleProvider}

