<template>
	<div class="content">
		<p>売上データ(分類:{{ cid }})</p>
		<Infotop :campaignId="cid" />
		<Stripe  :campaignId="cid" />
		<h3>銀行</h3>
	</div>

</template>

<script>
import Infotop from '../components/Infotop.vue'
import Stripe from '../components/stripe.vue'

export default {
	components: { Infotop, Stripe },
	props: ['campaignId'],
	setup(props) {

		const cid = props.campaignId ? props.campaignId : 'main'

		return { cid }
	}

}
</script>

<style>

</style>