const roleText = (role) => {
	const result
	= role === "client" ? "クライアント"
	:	role === "staff" ? "スタッフ"
	: role === "manager" ? "管理者"
	: "エラー"

	return result
}

export default roleText
