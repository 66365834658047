import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {projectAuth} from './firebase/config'

import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Sidebar from 'primevue/sidebar';
import Avatar from 'primevue/avatar';
import ConfirmationService from 'primevue/confirmationservice';
import BadgeDirective from 'primevue/badgedirective';

// import 'primevue/resources/themes/md-light-indigo/theme.css'
import 'primevue/resources/themes/vela-blue/theme.css'
// import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import './assets/main.css'

let app

projectAuth.onAuthStateChanged(() => {
	if(!app) {
		app = createApp(App)
		app.use(router)

		app.use(PrimeVue);
		app.use(ConfirmationService);
		app.component('Dialog', Dialog);
		app.component('Button', Button);
		app.component('DataTable', DataTable);
		app.component('Column', Column);
		app.component('Menubar', Menubar);
		app.component('Menu', Menu);
		app.component('Avatar', Avatar);
		app.component('Sidebar', Sidebar);
		app.directive('badge', BadgeDirective);

		app.mount('#app')
	}
})

