<template>
	<h3>Stripe</h3>

  <div class="orders-window">
  	<div class="error" v-if="dbError">DBエラー: {{ dbError }}</div>
		<div v-if="orders" class="messages" ref="messages">
			<div v-for="order in orders" :key="order.id" class="single">

				<!-- <p>Amount Due:{{order.amount_due}}</p> -->
				請求タイプ:{{order.billing_reason}}

				<div v-if="order?.lines.data">
					<!-- <p>Amount:{{order.lines.data[0].amount}}</p> -->
					<span class="name">{{order.lines.data[0].description}}</span>
					金額:{{order.amount_paid}}円
				</div>
				<!-- <span class="message">{{ order.itemname }}</span>
				数量:{{order.itemcount}} -->

				<span class="name">購入者名:{{ order.customer_name }}</span>
				Email:{{order.customer_email}}
				<span class="created-at">{{ order.createdAt }}前</span>
			</div>
		</div>
	</div>

</template>

<script>
import { computed, onUpdated } from '@vue/runtime-core'
import {ref} from 'vue'
import getCollection from '../composables/getCollection'
import {formatDistanceToNow} from 'date-fns'
import ja from 'date-fns/locale/ja'

export default {
	props: ['campaignId'],
	setup(props) {

		const {error: dbError, documents}
			= getCollection(`/systemTest/v1/stripe/${props.campaignId}/events`, ["event_type","==","invoice.paid"])

		const orders = computed(() => {
			if(documents.value) {
				return documents.value.map(doc => {
					const dateTime = new Date(doc.timeStamp * 1000);
					const time = formatDistanceToNow(dateTime,{locale: ja})
					return {...doc, createdAt: time}
				})

			}
		})

		const messages = ref(null)
		onUpdated(() => {
			messages.value.scrollTop = messages.value.scrollHeight
		})

		return { dbError, orders, messages }
	}

}
</script>

<style scoped>
  .orders-window {
    background: #fafafa;
    padding: 30px 20px;
  }
  .single {
    margin: 25px 14px;
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    padding: 0px;
    border-radius: 10px;
    background: white;
  }
  .created-at {
    display: block;
    color: #999;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .name {
    font-weight: bold;
    margin-right: 6px;
  }
  .messages {
    max-height: 400px;
    overflow: auto;
  }
</style>