<template>
	<div class="content">
		<p>アカウント登録手続き中です。</p>
	</div>
</template>

<script>
export default {

}
</script>

<style>

</style>