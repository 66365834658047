import { ref } from 'vue'
import { projectAuth, googleProvider } from '../firebase/config'

const error = ref(null)
const isPending = ref(false)

const login = async () => {
	error.value = null
	isPending.value = true

	try {
		const res = await projectAuth.signInWithPopup(googleProvider)
		if (!res) {
			throw new Error('ログインエラー')
		}

		error.value = null
		isPending.value = false
		// console.log(res)
		return res
	} catch (e) {
		error.value = e.message
		isPending.value = false
	}
}

const useLogin = () => {
	return {error, isPending, login}
}

export default useLogin
