import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Users from '../views/Users.vue'
import PendingSignup from '../views/auth/PendingSignup.vue'
import CampaignSummary from '../views/CampaignSummary.vue'
import {projectAuth} from '../firebase/config'


// const redirectToHomeIfLoggedIn = (to, from, next) => {
//   const user = projectAuth.currentUser
//   if(user) next({name: 'Dashboard'})
//   else next()
// }

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/campaigns/:campaignId',
    name: 'CampaignSummary',
    component: CampaignSummary,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    // beforeEnter: redirectToHomeIfLoggedIn,
  },
  {
    path: '/pending',
    name: 'PendingSignup',
    component: PendingSignup,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const user = projectAuth.currentUser
  let role = null

  if (user) {
    const token = await user.getIdTokenResult()
    role = token.claims.role
  }
  console.log(`role: ${role}`)

  if (to.name !== 'Login' && to.name !== 'PendingSignup') {
    if (!user) {
      next({ name: 'Login' })
    } else if ( user && !role) {
      next({ name: 'PendingSignup' })
    } else {
      next()
    }
  } else if (to.name !== 'PendingSignup') {
    if (!user) {
      next()
    } else if ( user && !role) {
      next({ name: 'PendingSignup' })
    } else {
      // next()
      next({ name: 'Dashboard' })
    }
  } else {
    if (!user) {
      next({ name: 'Login' })
    } else if ( user && !role) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }

})

export default router
