<template>
	<Avatar
		@click="toggle"
		aria-haspopup="true" aria-controls="overlay_menu"
		:image="user.photoURL" shape="circle"
		/>
	<Menu id="overlay_menu" ref="profileMenu"
		:model="profileItems" :popup="true" />
</template>

<script>
import { ref } from 'vue'
import useLogout from '../composables/useLogout'
import {useRouter} from 'vue-router'

export default {
	props: ['user'],
	setup(props) {
		const {error, logout} = useLogout()
		const router = useRouter()

    const profileMenu = ref();
    const profileItems = ref([
      {
        label: props.user.email,
        items: [
        	{
          	label: 'ログアウト',
          	icon: 'pi pi-sign-out',
          	command: async () => {
							await logout()
							if(!error.value) router.push({name: 'Login'})
          	}
        	}
      	]
			},

    ]);

    const toggle = (event) => {
        profileMenu.value.toggle(event);
    };

		return { profileMenu, profileItems, toggle}
	}
}
</script>

<style scoped>
.p-avatar {
	cursor: pointer;
}

</style>